import { Injectable } from "@angular/core";
import { AuthApiService } from "./auth-api.service";
import { AuthHolderService } from "./auth-holder.service";
import { ServiceProviderApiService } from "./service-provider-api.service";




@Injectable({
    providedIn: 'root'
})


export class PreLoadAllDataService {





    constructor (private authApiService: AuthApiService, private authHolderService: AuthHolderService,
        private serviceProviderApiService: ServiceProviderApiService ) {

    }

    load () {

        return new Promise ((resolve, reject) => {
            if (this.authHolderService.getJwtToken) {
                Promise.all([this.get_userData()]).then((res) => {
                    resolve(true)
                }).catch((e) =>{
                    resolve(true);
                })
            } else {
                resolve(true);
            }
        })

  
    }

    get_userData () {
        return new Promise ((resolve, reject) => {
            this.authApiService.getLoginUserDetail().subscribe( (res) => {
                console.log(res);
                const me: any = res;
                this.authHolderService.loggedinUser = res;
                if (me.role?.slug === 'service-provider') {
                    Promise.all([this.getServiceProvider()]).then((res) => {
                        resolve(true);
                    }).catch((err) => {
                        resolve(true)
                    })
                } else {
                    resolve(true);
                } 
            })
        })      
    }


    getServiceProvider () {
        return new Promise((resolve, reject) => {
            let userData = this.authHolderService.user
            let query:any = {
              where: {
                userId: userData['id']
              },
              include: [
                { relation: 'createdByUser' },
                { relation: 'address', scope: { include: [ { relation: 'country' }, { relation: 'state' }, { relation: 'city' } ] } },
                { relation: 'images',  },
                { relation: 'categories', scope: { include: [ { relation: 'images' } ] } },
              ]
            }
            query = encodeURI(JSON.stringify(query));
            this.serviceProviderApiService.getServiceProvider(query).subscribe(
              data => {
                if (data && data.length) {
                    this.authHolderService.loggedinUser__set_ServiceProvider = data[0];
                    resolve(true);
                } else {
                    resolve(true);
                }
              }, error => {
                 resolve(true);
              }
            )
        })
    }
}