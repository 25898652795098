import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { ModuleRoutingGuardService } from './module-routing-guard.service';
import { Observable } from 'rxjs';
import { AuthHolderService } from '../auth-holder.service';


@Injectable({
    providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

    public isLoggedIn: boolean = false;
    public login_user_data:any;
    public login_user_token: any;
    public login_user_type: any;
    public login_user_permission:any;

    constructor (private router: Router, private moduleRoutingGuardService: ModuleRoutingGuardService, 
        private authHolderService: AuthHolderService ) {
        
    }



    canActivate ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {       
        var k = this.method_for_activate_routes(route, state);
        return this.isLoggedIn;
    }


    method_for_activate_routes ( route, state ): Observable<boolean> | boolean {
        // alert('aaaa')
        this.login_user_data = this.authHolderService.user;

        // console.log(this.login_user_data, "login_user_data")

        this.login_user_token = this.authHolderService.getJwtToken;
        
        // this.login_user_permission = this.cookiesService.get_user_login_role_permission();

        // this.login_user_type = this.login_user_data['user']['userType'];

        this.isLoggedIn = this.moduleRoutingGuardService.routing_is_authenticate_or_not(route, state, this.login_user_data, this.login_user_token);
        
        // this.isLoggedIn = true;

        // alert('this.isLoggedIn = ' + this.isLoggedIn);

        // this.isLoggedIn = true;
        return this.isLoggedIn;
    }

}