import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotifierModule } from 'angular-notifier';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderModule } from './shared/loader/loader.module';
import { V24ErrorHttpInterceptor } from './shared/v24-error-httpinterceptor.service';
import { customNotifierOptions } from './shared/contants/notificationConfig';
import { PreLoadAllDataService } from './commonServices/preLoadAllData.service';

// import { ModalModule } from 'ngx-bootstrap';
// or
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

export function preLoadProviderFactory( preLoadAllDataService: PreLoadAllDataService) {
  return () => preLoadAllDataService.load();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule, ReactiveFormsModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    LoaderModule,
    HttpClientModule,
    NotifierModule.withConfig(customNotifierOptions),
    ModalModule.forRoot()
  ],
  providers: [
    PreLoadAllDataService,
    { provide: HTTP_INTERCEPTORS,  useClass: V24ErrorHttpInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: preLoadProviderFactory, deps: [ PreLoadAllDataService ], multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
