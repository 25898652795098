import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../model/user-mdel';
import { APIService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  constructor(private httpClient: HttpClient ){}


  createUser(data:User){
    return this.httpClient.post(APIService.signUp, data);
  }

  updateUser(data){
    return this.httpClient.put(`${APIService.users}/${data.id}`, data);
  }


  getUsers(query): Observable<Array<any>>{
    return this.httpClient.get<Array<any>>(`${APIService.users}?filter=${query}`);
  }

  getUsersCount (query): Observable<any> {
    return this.httpClient.get<any>(`${APIService.users_count}?where=${query}`)
  }

  sendVendorApproveMail (dataDto): Observable<any> {
    return this.httpClient.post<any>(`${APIService.sendVendorApproveMail}`, dataDto);
  }


}
