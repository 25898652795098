import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable, Subject, Subscription } from "rxjs";
import { ConfirmActionComponent } from "../shared/confirm-action/confirm-action.component";
// import { UserLoginComponent } from "../website/user-auth/user-login/user-login.component";
// import { UserRegisterComponent } from "../website/user-auth/user-register/user-register.component";
import { APIService } from "./api.service";


@Injectable({
  providedIn: 'root'
})
export class AuthHolderService {
  public tokenSubject = new Subject<any>();
  public tokenIsRunning: Subscription;
  private tokenObservable = this.tokenSubject.asObservable();

  private accessToken= undefined;
  private refreshToken= undefined;
  isValid = false;
  constructor(private httpClient: HttpClient, private router: Router, private dialog: MatDialog){}

  userGetNewToken(): Observable<any> {
    const data = {
      "refreshToken": this.getRefreshTokn
    }
    if (this.tokenIsRunning) {
        return this.tokenObservable;
    }

    this.tokenIsRunning = this.httpClient.post(APIService.refreshToken, data).subscribe(res => {
        this.tokenSubject.next(res);
        this.tokenIsRunning = null;
    }, (err) => {
        this.tokenSubject.next(err);
        this.tokenIsRunning = null;
    });
    return this.tokenObservable;
  }

  set token(data){
    this.accessToken = data.accessToken;
    this.refreshToken = data.refreshToken;
    localStorage.setItem('accessToken', this.accessToken);
    localStorage.setItem('refreshToken', this.refreshToken);
  }

  set authToken(data){
    this.accessToken = data.accessToken;
    localStorage.setItem('accessToken', this.accessToken);
  }

  set loggedinUser(data){
    localStorage.setItem('userDetail', JSON.stringify(data));
  }

  get user(): any{
    let user = localStorage.getItem('userDetail');
    user = user ? JSON.parse(user) : {}
    return user;
  }

  get getJwtToken(){
    return this.accessToken || localStorage.getItem('accessToken');
  }

  get getRefreshTokn(){
    return this.refreshToken || localStorage.getItem('refreshToken');
  }


  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};


get isAccessTokenValid(): boolean {
  this.isValid = false;
  const token = this.getJwtToken;
  if (token && token != null) {
      const helper = new JwtHelperService();
      try {
          this.isValid = !helper.isTokenExpired(token);
      } catch (e) {
          this.logoutFromAllAuth();
      }
  }
  return this.isValid;
}

get isRefreshTokenValid(): boolean {
  let isValid = false;
  const refreshToken = this.getRefreshTokn;
  if (refreshToken && refreshToken != null) {
      const helper = new JwtHelperService();
      try {
          isValid = !helper.isTokenExpired(refreshToken);
      } catch (e) {
          this.logoutFromAllAuth();
      }
  }
  return isValid;
}

private getTokenExpirationDate(token: string): Date {
  const exp = this.parseJwt(token).exp;
  const date = new Date(0);
  if (exp === undefined) {
      return date;
  }

  date.setUTCSeconds(exp);
  return date;
}

  clearToken(){
    // localStorage.removeItem('accessToken');
    // localStorage.removeItem('refreshToken');
    // localStorage.removeItem('userDetail');
    localStorage.clear()
  }

  logoutFromAllAuth(){
    this.clearToken();
  }

  ngOnDestroy() {
    if (this.tokenIsRunning) {
       this.tokenIsRunning.unsubscribe();
    }
  }

  set loggedinUser__set_ServiceProvider(data){
    localStorage.setItem('serviceProvider', JSON.stringify(data));
  }

  get loggedinUser_Get_ServiceProvider(): any{
    let user = localStorage.getItem('serviceProvider');
    user = user ? JSON.parse(user) : {}
    return user;
  }

  get_stripe_status () {
    return  localStorage.getItem('hasStripe');
   }

   set_stripe_status () {
     localStorage.setItem('hasStripe', 'true');
   }


//    method_for_userLogin () {
//     this.dialog.open(UserLoginComponent, {
//         data: {},
//         width: '529px',

//     })
// }


//   method_for_userRegistration () {
//       this.dialog.open(UserRegisterComponent, {
//           data: {},
//           width: '529px',

//       })
//   }

   logout(isWebsite = true){
    this.dialog.open(ConfirmActionComponent,{
      width: '30em',
      data: {
        title: 'Logout',
        message: 'Would you like to logout?'
      }
    }).afterClosed().subscribe(res => {
      if(res){
        this.clearToken();
        if(isWebsite){
          this.router.navigate(['/']);
          setTimeout(() => {
            window.location.reload();
          })
        }else{
          this.router.navigate(['auth/login']);
        }
      }
    })
  }
}
