import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class APIService {
  public static readonly imageBaseUrl = environment.imageUrl;

  public static readonly login = environment.baseUrl + 'users/refresh-login';
  public static readonly me = environment.baseUrl + 'users/me';
  public static readonly signUp = environment.baseUrl + 'signUp';

  public static readonly refreshToken = environment.baseUrl + 'refresh';

  public static readonly forgotPassword =
    environment.baseUrl + 'users/reset-password/init';
  public static readonly resetPassword =
    environment.baseUrl + 'users/reset-password/finish';

  public static readonly changePassword =
    environment.baseUrl + 'users/change-password';

  public static readonly createAccount = environment.baseUrl + 'createUser';

  public static readonly users = environment.baseUrl + 'users';

  public static readonly users_dashboardAnalysis =
    environment.baseUrl + 'users/dashboardAnalysis/';

  public static readonly users_count = environment.baseUrl + 'users/count';
  public static readonly uniqueEmail =
    environment.baseUrl + 'user/email-unique';

  public static readonly sendVendorApproveMail =
    environment.baseUrl + 'users/sendVendorApproveMail';

  public static readonly images = environment.baseUrl + 'images';
  public static readonly image_count = environment.baseUrl + 'images/count';

  public static readonly saveImage = environment.baseUrl + `images`;

  // Image Upload
  public static readonly uploadImage =
    environment.baseUrl + `containers/${environment.containerName}/upload`;

  //category API
  public static readonly categories = environment.baseUrl + 'categories';
  public static readonly categories_count =
    environment.baseUrl + 'categories/count';
  public static readonly sub_categories =
    environment.baseUrl + 'sub-categories';
  public static readonly sub_categories_count =
    environment.baseUrl + 'sub-categories/count';

  public static readonly categories_wo_token =
    environment.baseUrl + 'categories-wo-token';

  // address API
  public static readonly addresses = environment.baseUrl + 'addresses';
  public static readonly countries = environment.baseUrl + 'countries';
  public static readonly cities = environment.baseUrl + 'cities';
  public static readonly states = environment.baseUrl + 'states';

  // service provider user
  public static readonly spUser =
    environment.baseUrl + 'create-user-service-provider';

  public static readonly serviceProvider =
    environment.baseUrl + 'service-providers';
  public static readonly serviceProviderCount =
    environment.baseUrl + 'service-providers/count';
  public static readonly userServiceProvider =
    environment.baseUrl + 'create-user-service-provider';
  public static readonly serviceProviderCommison =
    environment.baseUrl + 'service-provider-commission-rates';
  // become service Provider
  public static readonly serviceProviderWeb =
    environment.baseUrl + 'become-service-providers';
  public static readonly serviceProviderWebCount =
    environment.baseUrl + 'become-service-providers/count';

  // Stripe Payment
  public static readonly StripePayments =
    environment.baseUrl + 'stripe-payments';
  public static readonly StripePayments_onboardVendor =
    environment.baseUrl + 'stripe-payments/onboardVendoronboardVendor';
  public static readonly StripePayments_onboardVendorRefresh =
    environment.baseUrl + 'stripe-payments/onboardVendorRefresh';
  public static readonly StripePayments_customerPlaceOrder =
    environment.baseUrl + 'stripe-payments/customerPlaceOrder';
  public static readonly StripePayments_getConnectedAccountData =
    environment.baseUrl + 'stripe-payments/getConnectedAccountData';
  public static readonly StripePayments_getConnectedAccount_loginLink =
    environment.baseUrl + 'stripe-payments/getConnectedAccount_loginLink';
  public static readonly StripePayments_getStripeCustomerCard =
    environment.baseUrl + 'stripe-payments/getStripeCustomerCard';

  // Admin Setting
  public static readonly AdminSetting = environment.baseUrl + 'admin-settings';
  public static readonly AdminSetting_termCondition =
    environment.baseUrl + 'admin-settings/term-condition';
  public static readonly AdminSetting_privacyPolicy =
    environment.baseUrl + 'admin-settings/privacy-policy';
  public static readonly AdminSetting_userInfo =
    environment.baseUrl + 'admin-settings/information-for-users';
  public static readonly AdminSetting_providersInfo =
    environment.baseUrl + 'admin-settings/information-for-service-providers';

  // Cart-item
  public static readonly Cart_items = environment.baseUrl + 'cart-items';

  // contact us
  public static readonly ContactUs = environment.baseUrl + 'contact-us';

  // Order
  public static readonly Orders = environment.baseUrl + 'orders';
  public static readonly Orders_count = environment.baseUrl + 'orders/count';
  public static readonly Orders_Status =
    environment.baseUrl + 'ordersStatusServiceProvider';
  public static readonly Orders_paymentStatus =
    environment.baseUrl + 'paymentStatus';
  public static readonly Orders_PayToCompleteOrder =
    environment.baseUrl + 'ordersPayToCompleteOrder';

  // payment
  public static readonly PaymentDetails =
    environment.baseUrl + 'payment-details';
  public static readonly PaymentDetails_count =
    environment.baseUrl + 'payment-details/count';

  // dashboard Api
  public static readonly Dashboard_ServiceProvider_totalIncome =
    environment.baseUrl + 'serviceProvider/totalIncome';
  public static readonly Dashboard_ServiceProvider_todayIncome =
    environment.baseUrl + 'serviceProvider/todayIncome';
  public static readonly Dashboard_ServiceProvider_totalOrder =
    environment.baseUrl + 'serviceProvider/totalOrder';
  public static readonly Dashboard_ServiceProvider_todayOrder =
    environment.baseUrl + 'serviceProvider/todayOrder';
  public static readonly Dashboard_ServiceProvider_orderIncomeChart =
    environment.baseUrl + 'serviceProvider/orderIncomeChart';

  public static readonly Dashboard_Admin_totalIncome =
    environment.baseUrl + 'admin/totalIncome';
  public static readonly Dashboard_Admin_todayIncome =
    environment.baseUrl + 'admin/todayIncome';
  public static readonly Dashboard_Admin_totalOrder =
    environment.baseUrl + 'admin/totalOrder';
  public static readonly Dashboard_Admin_todayOrder =
    environment.baseUrl + 'admin/todayOrder';
  public static readonly Dashboard_Admin_orderIncomeChart =
    environment.baseUrl + 'admin/orderIncomeChart';

  public static readonly Dashboard_Admin_ratingStar =
    environment.baseUrl + 'admin/ratingstar';

  // Review
  public static readonly Reviews = environment.baseUrl + 'reviews';
  public static readonly Reviews_count = environment.baseUrl + 'reviews/count';

  //Contact us

  public static readonly contactUs = environment.baseUrl + 'contact-us';
}
