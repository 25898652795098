import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class ServiceProviderApiService {

  constructor(private httpClient: HttpClient) { }

  getServiceProvider(query): Observable<any>{
    return this.httpClient.get<any>(`${APIService.serviceProvider}?filter=${query}`);
  }

  createServiceProvider(data){
    return this.httpClient.post(APIService.serviceProvider, data);
  }

  updateServiceProvider(data){
    return this.httpClient.patch(`${APIService.serviceProvider}/${data.id}`, data);
  }

  getServiceProviderCount (query): Observable<any> {
    return this.httpClient.get<any>(`${APIService.serviceProviderCount}?where=${query}`)
  }

  createUser(data){
    return this.httpClient.post(APIService.userServiceProvider, data);
  }


  updateUser(data):Observable<any>{
    return this.httpClient.put<any>(`${APIService.users}/${data.id}`, data);
  }

  getServiceProviderById (id) {
    return this.httpClient.get(`${APIService.serviceProvider}/${id}`)
  }

}
