import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  constructor(private httpClient: HttpClient ){}



  login(data:any): Observable<any>{
    return this.httpClient.post(APIService.login, data);
  }

  sendEmailResetPassword(data:any){
    return this.httpClient.post(APIService.forgotPassword, data);
  }

  resetPassword(data:any){
    return this.httpClient.put(APIService.resetPassword, data);
  }

  getLoginUserDetail(){
    return this.httpClient.get(APIService.me);
  }


  changePassword (data): Observable<any> {
    return this.httpClient.post<any>(APIService.changePassword, data);
  }
}
