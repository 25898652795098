import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export class LoaderState {
  show = 0;
}

// Custom

@Injectable({ providedIn: 'root' })

export class LoaderService {

    private loaderSubject = new Subject<LoaderState>();
    loaderState = this.loaderSubject.asObservable();
    count = 0;
    constructor() { }

    show() {
        if (this.count >= 0) {
            this.loaderSubject.next({ show: ++this.count } as LoaderState);
        } else {
            this.count = 0;
            this.loaderSubject.next({ show: this.count } as LoaderState);
        }
    }

    hide() {
        if (this.count > 0) {
            this.loaderSubject.next({ show: --this.count } as LoaderState);
        } else {
            this.count = 0;
            this.loaderSubject.next({ show: this.count } as LoaderState);
        }
    }

    forceHide() {
        this.count = 0;
        this.loaderSubject.next({ show: this.count } as LoaderState);
    }

    reloadRoute(router) {
        const currentRoute = router.url;
        router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            router.navigate([currentRoute]); // navigate to same route
        });
    }
}
