import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthApiService } from 'src/app/commonServices/auth-api.service';
import { AuthHolderService } from 'src/app/commonServices/auth-holder.service';
import { NotificationService } from 'src/app/commonServices/notification.service';
// import { WebAuthInternalService } from 'src/app/commonServices/webAuthInternal.service';
import { UserRegisterComponent } from '../user-register/user-register.component';
// import { UserRegisterComponent } from '../user-register/user-register.component';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'App-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})

export class UserLoginComponent implements OnInit {
  public matcher = new MyErrorStateMatcher();
  public hide = true;
  public signUpUserData;
   loginForm: FormGroup;


  constructor (
    private dialgo: MatDialog,
    private dialogRef: MatDialogRef<UserLoginComponent>,
    private formBuilder: FormBuilder,
    private authApiService: AuthApiService,
    private notificationService: NotificationService,
    private router: Router,
    private authHolderService: AuthHolderService,

    ) {

  }


ngOnInit () {
  this.validateForm ();
}


validateForm() {
  this.loginForm = this.formBuilder.group({
    // firstName: ['', [Validators.required, this.noWhitespaceValidator]],
    // lastName: ['', [Validators.required, this.noWhitespaceValidator]],
    email: ['', [Validators.required, Validators.email, this.noWhitespaceValidator]],
    password: ['', [Validators.required, Validators.minLength(8),
      Validators.maxLength(25),
      Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{7,}'),
      this.noWhitespaceValidator
    ]],
    termAndCondition:[false, Validators.required],
  })
}
public noWhitespaceValidator(control: FormControl) {
  const isWhitespace = (control.value && typeof control.value === "string" && control.value.trim().length === 0);
  const isValid = !isWhitespace;
  return isValid ? null : { 'whitespace': true };
}
get f() { return this.loginForm.controls; };


login (){
  if(this.loginForm.invalid){
    return false;
  }
  this.authApiService.login(this.loginForm.value)
  .subscribe(res => {
    this.authHolderService.token = res;
    this.getUserDetail();
    // this.router.navigate(['/home/dashboard']);

  });
}


getUserDetail(){
  this.authApiService.getLoginUserDetail().subscribe( (res) => {
      console.log(res);
      const me: any = res;

      if(me.role?.slug === 'root' || me.role?.slug === 'super-admin' || me.role?.slug === 'service-provider'){
        this.authHolderService.loggedinUser = res;
        this.notificationService.get_Notification('error', `You don't have permission to login`);
        this.authHolderService.clearToken();
        this.loginForm.reset();
      } else {
        this.authHolderService.loggedinUser = res;
        this.dialgo.closeAll();
        window.location.reload();
      }
    })
}




  forgotPassword(){
    // this.dialogRef.close();
    this.dialgo.closeAll();
    this.router.navigate(['auth/forgot-password']);
  }

  userRegister () {
    this.dialgo.open(UserRegisterComponent, {
      data: {},
      width: '529px',
      // height: '100vh'
    })
    this.dialogRef.close();
  }



}
