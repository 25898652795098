import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService, LoaderState } from './loader.service';



@Component({
    selector: 'app-loader',
    templateUrl: 'loader.component.html'
})
export class LoaderComponent implements OnInit, OnDestroy {
    show = 0;
    private subscription: Subscription;
    constructor(
        private loaderService: LoaderService
    ) { }

    ngOnInit() {
        this.subscription = this.loaderService.loaderState.subscribe((state: LoaderState) => {
            this.show = state.show;
        });
    }
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
