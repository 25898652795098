import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, mergeMap } from "rxjs/operators";
import { APIService } from "../commonServices/api.service";
import { AuthHolderService } from "../commonServices/auth-holder.service";
import { NotificationService } from "../commonServices/notification.service";

import { LoaderService } from "./loader/loader.service";

@Injectable()
export class V24ErrorHttpInterceptor implements HttpInterceptor {
  byPassRequest = [APIService.refreshToken]
  constructor(private notificationService: NotificationService,
    private loaderService: LoaderService,
    private authHolderService: AuthHolderService,
    private router: Router){}

  intercept(req: HttpRequest<any>, next: HttpHandler):  Observable<HttpEvent<any>>{
    this.showLoader();
    // if(!this.authHolderService.token && !this.authHolderService.getRefreshTokn){
    //   this.hideLoader();
    //   return next.handle(req) as Observable<HttpEvent<any>>
    // }
    if(this.authHolderService.isAccessTokenValid && this.authHolderService.getJwtToken && !this.findInArray(req.url)){
      req = req.clone(
        {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${this.authHolderService.getJwtToken}`
          })
        }
      )
      if(req.url.includes('reset-password/finish')){
        req = req.clone(
          {
            responseType: 'text'
          })
      }
      return this.nextHandler(req,next);
    }else if(this.authHolderService.isRefreshTokenValid && this.authHolderService.getJwtToken && this.authHolderService.getRefreshTokn && !this.findInArray(req.url)){
      return this.authHolderService.userGetNewToken()
      .pipe(mergeMap(res => {
        if(res){
          this.authHolderService.authToken = res;
        }
        const reqClone = req = req.clone(
          {
            headers: new HttpHeaders({
              'Authorization': `Bearer ${this.authHolderService.getJwtToken}`
            })
          }
        )
        return this.nextHandler(reqClone,next);
      }))
    }else if(!this.authHolderService.isRefreshTokenValid && this.authHolderService.getRefreshTokn){
        this.authHolderService.clearToken();
        this.router.navigate(['/']);
        this.onEnd();
    }else{
      if(req.url.includes('reset-password/finish')){
        req = req.clone(
          {
            responseType: 'text'
          })
      }
      return this.nextHandler(req,next);
    }

  }

  nextHandler(req, next): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error("Error from error interceptor", error);
        this.onEnd();
        if (error.error?.error?.code == 'ER_DUP_ENTRY') {
          this.notificationService.get_Notification('error', "Email already exit");
        } else {
          this.notificationService.get_Notification('error', error.error?.error?.message);
        }        
        return throwError(error);
        }),
        finalize(() => {
          this.onEnd();
      })
    ) as Observable<HttpEvent<any>>
  }

  private onEnd(): void {
    this.hideLoader();
}
private showLoader(): void {
    this.loaderService.show();
}
private hideLoader(): void {
    this.loaderService.hide();
}

findInArray(reqUrl){
  return this.byPassRequest.some(url => {
    return reqUrl.indexOf(url) > -1
  });
}
}
