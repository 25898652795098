import { Injectable } from "@angular/core";
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import * as _ from "lodash";
import { NotificationService } from '../notification.service';
import { MatDialog } from "@angular/material/dialog";
import { AuthHolderService } from "../auth-holder.service";
import { WebAuthInternalService } from "../webAuthInternal.service";

@Injectable({
    providedIn: 'root'
})

export class ModuleRoutingGuardService {

    public isLoggedIn: boolean = false;
    public login_user_data:any;
    public login_user_token: any;
    public login_user_bussiness:any;
    public login_user_bussiness_provider:any;

    public login_user_type: any;

    public current_route;


    constructor ( private router: Router,  private authHolderService: AuthHolderService,
        public notificationService: NotificationService, private webAuthInternalService: WebAuthInternalService) {

    }



    routing_is_authenticate_or_not (route, state, login_user_data, login_user_token) {

        // alert(1)
        this.isLoggedIn = false;
        this.login_user_data = login_user_data;
        this.login_user_token = login_user_token;
        this.current_route = route.routeConfig.path;


        if ( this.current_route === 'auth' ) {
            if ( this.login_user_token != null ) {
                this.isLoggedIn = this.method_for_login_user_authenticate ( route, state, login_user_data, login_user_token );
                return this.isLoggedIn;
            } else {
                return true;
            }
        } else if ( this.current_route === 'login' ) {
            if ( this.login_user_token != null ) {
                this.isLoggedIn = this.method_for_login_user_authenticate ( route, state, login_user_data, login_user_token );
                return this.isLoggedIn;
            } else {
                return true;
            }
        }
        else if ( this.current_route === 'signup' ) {
            if ( this.login_user_token != null ) {
                this.isLoggedIn = this.method_for_login_user_authenticate ( route, state, login_user_data, login_user_token );
                return this.isLoggedIn;
            } else {
                return true;
            }
        }
        else if ( this.current_route === 'forgot-password' ) {
            if ( this.login_user_token != null ) {
                this.isLoggedIn = this.method_for_login_user_authenticate ( route, state, login_user_data, login_user_token );
                return this.isLoggedIn;
            } else {
                return true;
            }
        }
        else if ( this.current_route === 'reset-password' ) {
            if ( this.login_user_token != null ) {
                this.isLoggedIn = this.method_for_login_user_authenticate ( route, state, login_user_data, login_user_token );
                return this.isLoggedIn;
            } else {
                return true;
            }
        }
        else if ( this.current_route === 'thank-you' ) {
            if ( this.login_user_token != null ) {
                this.isLoggedIn = this.method_for_login_user_authenticate ( route, state, login_user_data, login_user_token );
                return this.isLoggedIn;
            } else {
                return true;
            }
        }
        else {
            if ( this.login_user_token != null ) {
                this.isLoggedIn = this.method_for_login_user_authenticate ( route, state, login_user_data, login_user_token );
                return this.isLoggedIn;
            } else {
                // this.router.navigate(['auth/login'])
                this.webAuthInternalService.userLogin();
            }
        }
    }



    method_for_login_user_authenticate ( route, state, login_user_data, login_user_token ) {
        this.login_user_data = login_user_data;
        this.login_user_token = login_user_token;

        // alert(this.current_route);

        if (this.login_user_data.roles.includes('root')) {
            if (this.current_route == 'home') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'dashboard') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'userManagement') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'serviceProvider') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'categories') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'manage-reports') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'transaction') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'manage-review') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'admin-setting') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'contact-us') {
              this.isLoggedIn = true;
            } else if (this.current_route == 'admin-order-details') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'details/:id') {
                this.isLoggedIn = true;
            }else {
                this.router.navigate(['home/dashboard']);
            }
        } else if (this.login_user_data.roles.includes('super-admin')) {
            if (this.current_route == 'home') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'dashboard') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'userManagement') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'serviceProvider') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'categories') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'manage-reports') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'transaction') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'manage-review') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'admin-setting') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'contact-us') {
              this.isLoggedIn = true;
            } else if (this.current_route == 'admin-order-details') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'details/:id') {
                this.isLoggedIn = true;
            } else {
                this.router.navigate(['home/dashboard']);
            }
        } else if (this.login_user_data.roles.includes('service-provider')) {
            if (this.current_route == 'home') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'sales-overview') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'admin-order-details') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'details/:id') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'stripe-connect') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'setting') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'edit-profile') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'change-password') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'company-detail') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'about-us') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'contact-us') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'privacy-policy') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'term-and-condition') {
                this.isLoggedIn = true;
            } else {
                this.router.navigate(['home/sales-overview']);
            }
        } else if (this.login_user_data.roles.includes('customer')) {
            if (this.current_route == 'service') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'service/:id') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'web-cart') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'user-profile') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'provider-requested/:id') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'order-details/:id') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'booking-history') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'profile-details') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'change-password') {
                this.isLoggedIn = true;
            } else if (this.current_route == 'user-address') {
                this.isLoggedIn = true;
            } else {
                this.router.navigate(['/']);
            }
        } else {
            this.router.navigate(['/']);
        }

        return this.isLoggedIn;

        // if (this.current_route === 'home') {
        //     this.isLoggedIn = true;
        // } else if (this.current_route === 'dashboard') {
        //     this.isLoggedIn = true;
        // } else if (this.current_route === 'userManagement') {
        //     this.isLoggedIn = true;
        // } else if (this.current_route === 'serviceProvider') {
        //     this.isLoggedIn = true;
        // } else if (this.current_route === 'categories' ) {
        //     this.isLoggedIn = true;
        // } else if (this.current_route === 'transaction') {
        //     this.isLoggedIn = true;
        // } else if (this.current_route === 'manage-reports') {
        //     this.isLoggedIn = true;
        // } else if (this.current_route === 'manage-review') {
        //     this.isLoggedIn = true;
        // } else if (this.current_route === 'setting') {
        //     this.isLoggedIn = true;
        // } else if (this.current_route === 'stripe-connect') {
        //     this.isLoggedIn = true;
        // } else if (this.current_route == 'sales-overview') {
        //     this.isLoggedIn = true;
        // } else {
        //     this.router.navigate(['home/dashboard']);
        // }



    //     if (this.login_user_data['role'] == 'Root') {
    //         // alert("Root ====== ");

    //         if (this.current_route == 'home') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'dashboard') {
    //             this.isLoggedIn = true;
    //         }


    //         // for customer router
    //         else if (this.current_route == 'customer') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'edit-customer/:id') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'add-customer') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'customer-detail/:customerId') {
    //             this.isLoggedIn = true;
    //         }

    //         // for vendor route
    //         else if (this.current_route == 'vendor') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'addVendors') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'addVendors/:userId') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'addVendors/:userId/:venderId') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'vendor-detail/:id') {
    //             this.isLoggedIn = true;
    //         }

    //         // for Profile Route
    //         else if (this.current_route == 'profile') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'address') {
    //             this.isLoggedIn = true;
    //         }

    //         // for Categories Route
    //         else if (this.current_route == 'categories') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'venderCategories') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'productCategories') {
    //             this.isLoggedIn = true;
    //         }

    //         // for vendor product
    //         else if (this.current_route == 'product/:vendorId') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'edit-product/:id') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'add-product') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'product-detail/:id') {
    //             this.isLoggedIn = true;
    //         }

    //         else if (this.current_route == 'setting') {
    //           this.isLoggedIn = true;
    //         }
    //         else if (this.current_route == 'order') {
    //           this.isLoggedIn = true;
    //         }

    //         else {
    //             this.router.navigate(['home/dashboard']);
    //         }
    //     }
    //     else if (this.login_user_data['role'] == 'Vendor') {
    //         console.log('*******', this.current_route)
    //         if (this.current_route == 'vendor') {
    //             this.isLoggedIn = true;
    //         }
    //         // else if (this.current_route == 'dashboard') {
    //         //     this.isLoggedIn = true;
    //         // }

    //         // for profile routing
    //         else if (this.current_route == 'profile') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'address') {
    //             this.isLoggedIn = true;
    //         }


    //         //for vendor routing
    //         else if (this.current_route == 'vendor-detail') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == "vendor") {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'addVendors/:userId') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'addVendors/:userId/:venderId') {
    //             this.isLoggedIn = true;
    //         }


    //         // for product list
    //         else if (this.current_route == 'productList') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'product-detail/:id') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'edit-product/:id') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'add-product') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'categories-request') {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == 'product-category-request') {
    //             this.isLoggedIn  = true;
    //         }

    //         // order
    //         else if (this.current_route == "order") {
    //             this.isLoggedIn = true;
    //         } else if (this.current_route == "manage-order") {
    //             this.isLoggedIn = true;
    //         }

    //     else if (this.current_route == 'vendor-category-request') {
    //       this.isLoggedIn  = true;
    //   }


    //         else {
    //             this.router.navigate(['vendor/vendor-detail']);
    //         }
    //     }
    //     else if (this.login_user_data['role'] == "User") {
    //         if (this.current_route == "select-your-product/:id") {
    //             this.isLoggedIn  = true;
    //         } else {
    //             this.authHolderService.logoutFromAllAuth();
    //             this.router.navigate(['/']);
    //         }
    //     }



        // else {
        //     this.authHolderService.logoutFromAllAuth();
        //     this.router.navigate(['/']);
        //     // this.router.navigate(['home/dashboard']);
        // }





        return this.isLoggedIn;
    }

    // method_for_customerId_not_present () {
    //     let login_impersonate = this.cookiesService.get_user_login_data(false);
    //     if (login_impersonate['impersonateId']) {
    //         let dataDto = {
    //             id: login_impersonate['id'],
    //             impersonateId: null
    //         }
    //         this.userApiService.put_user(dataDto).subscribe(
    //             data => {
    //               this.notificationService.get_Notification('success', "You cann't impersonate this account, CustomerId not present");
    //               this.cookiesService.impersonate_clear_all_data();
    //               setTimeout(() => {
    //                 location.reload();
    //               }, 1000)
    //             }, error => {
    //               this.commonHelperService.hide_loader();
    //               this.notificationService.get_Notification('error', this.commonHelperService.notificationMessage.message);
    //             }
    //         )
    //     } else {
    //         this.notificationService.get_Notification('error', 'CustomerId not present please contact admin');
    //         setTimeout (() => {
    //             this.cookiesService.clear_all_data();
    //             location.reload();
    //         }, 1000)
    //     }
    // }


    // timeDifference(date1,date2) {
    //     var difference = date1.getTime() - date2.getTime();

    //     var daysDifference = Math.floor(difference/1000/60/60/24);
    //     difference -= daysDifference*1000*60*60*24

    //     var hoursDifference = Math.floor(difference/1000/60/60);
    //     difference -= hoursDifference*1000*60*60

    //     var minutesDifference = Math.floor(difference/1000/60);
    //     difference -= minutesDifference*1000*60

    //     var secondsDifference = Math.floor(difference/1000);

    //     console.log('difference = ' +
    //       daysDifference + ' day/s ' +
    //       hoursDifference + ' hour/s ' +
    //       minutesDifference + ' minute/s ' +
    //       secondsDifference + ' second/s ');

    //       return daysDifference;
    //     // return hoursDifference;
    //     // return minutesDifference;
    // }

    // method_for_converting_utc_second_date (utcSeconds) {
    //     var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    //     d.setUTCSeconds(utcSeconds);
    //     return d
    // }

}
