
<button class="float-right" mat-icon-button aria-label="close dialog" mat-dialog-close><mat-icon>close</mat-icon></button>
<h2 class="mat-module-title hedding mb-0 pl-3 pr-3" mat-dialog-title>{{title}}</h2>
<div mat-dialog-content class="mat-module-body pt-3 pb-3">
  <p class="text mt-2 pl-0 pr-0">
    {{message}}
  </p>
</div>
<div class="mat-module-footer d-flex pt-2 pb-1 pl-3 pr-3 mt-1" mat-dialog-actions [align]="'end'">
  <button mat-button-raised class="btn btn-default button button-default mr-3" type="button" (click)="action(false)">{{noButton}}</button>
  <button mat-button-raised class="btn btn-danger button button-danger" type="button" (click)="action(true)">{{yesButton}}</button>
</div>

