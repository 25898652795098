<div class="module-body">

  <div class="auth-contant-panel">
    <button class="float-right mr-1" mat-icon-button aria-label="close dialog" mat-dialog-close><mat-icon>close</mat-icon></button>
    <div class="text-center pt-3 pb-3">
      <img src="../../../../assets/images/brand/logo-login.svg" alt="">
     
    </div>
    <form [formGroup]="signUpForm" (ngSubmit)="signUp()" class="form-contant w-100">
      <div class="auth-main-contant">

        <div class="auth-main-contant">
          <div class="d-flex w-100" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="12px"
            style="justify-content: space-between">
            <div fxFlex="48" class="d-flex">
              <div class="mat-form-group w-100 mb-1">
                <mat-form-field class="mat-input-form" appearance="fill">
                  <mat-label> Full Name </mat-label>
                  <input type="text" class="" matInput placeholder="firstName" formControlName="firstName"/>
                  <img style="top: -3px" class="login-icon" src="../../../assets/images/auth-icon/full-name-icon.svg"
                    alt="" />
                </mat-form-field>
              </div>
            </div>
            <div fxFlex="48" class="d-flex">
              <div class="mat-form-group w-100 mb-1">
                <mat-form-field class="mat-input-form" appearance="fill">
                  <mat-label> last Name </mat-label>
                  <input type="text" class="" matInput placeholder="last Name"  formControlName="lastName"/>
                  <img style="top: -3px" class="login-icon" src="../../../assets/images/auth-icon/full-name-icon.svg"
                    alt="" />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="mat-form-group w-100 mb-1">
            <mat-form-field class="mat-input-form" appearance="fill">
              <mat-label> Email </mat-label>
              <input type="email" class="" matInput placeholder="Email" formControlName="email"/>
              <img class="login-icon" src="../../../assets/images/auth-icon/login-icon.svg" alt="" />
            </mat-form-field>
          </div>

          <div class="mat-form-group w-100 mb-0">
            <mat-form-field class="mat-input-form" appearance="fill">
              <mat-label> Password </mat-label>
              <input type="Password" class="" matInput placeholder="Password" [errorStateMatcher]="matcher"
                formControlName="password" />
              <img class="login-icon" style="top: -3px" src="../../../assets/images/auth-icon/password-icon.svg"
                alt="" />
            </mat-form-field>
          </div>

          <div class="mat-form-group w-100 mb-0">
            <mat-form-field class="mat-input-form" appearance="fill">
              <mat-label> Confirm Password </mat-label>
              <input type="Password" class="" matInput placeholder="Confirm Password" [errorStateMatcher]="matcher"
                formControlName="cnfPassword" />
              <img class="login-icon" style="top: -3px" src="../../../assets/images/auth-icon/password-icon.svg"
                alt="" />

                <mat-error *ngIf="f['cnfPassword'].errors?.passwordMissmatched">
                  Password and Confirm password does not match.
                </mat-error>
            </mat-form-field>
          </div>








          <div class="mat-form-group w-100 mb-1">
            <label class="form-control-label whitecheck float-left mb-0" for="">
              <input class="checkbox-input" id="termscheckbox" type="checkbox" value="checkvb" formControlName="termAndCondition"/>
              <label for="termscheckbox" class="checkbox-label-text mb-0">
                <span class="user-terms-text sign-up-text">I agree to the <a class="ml-1 mr-1">Terms & Conditions</a>and <a>Privacy
                    Policye</a></span>
              </label>
            </label>

          </div>

          <div class="mat-form-group w-100 mt-4 mb-1">
            <button class="button button-primary button-secondary w-100" style="height: 42px;">
              Sign Up
            </button>
          </div>
          <div class="mat-form-group w-100 mt-2 mb-1 text-center">
            <!-- <span class="sign-up-text" (click)="userLogin()">Already have an account? <a> Sign In</a></span> -->
          </div>

        </div>
  
        </div>
        <!-- <div class="mat-form-group w-100 mt-4 mb-0"  [routerLink]="['auth/login']">
          <a class="button button-primary button-primary-light w-100">
            Sign up as Professional
          </a>
        </div> -->
    </form>
  </div>
</div>
