<div class="module-body">
  <div class="auth-contant-panel">
      <button class="float-right mr-1" mat-icon-button aria-label="close dialog" mat-dialog-close><mat-icon>close</mat-icon></button>
      <!-- <div mat-dialog-title class="dialog-title">
        <h2 class="hedding float-left">Service Provider Details</h2>
        <button class="float-right" mat-icon-button aria-label="close dialog" mat-dialog-close><mat-icon>close</mat-icon></button>
      </div> -->    
    <div class="text-center pt-3 pb-3">
      <img src="../../../../assets/images/brand/logo-login.svg" alt="">
   
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="login()" class="form-contant w-100">
      <div class="auth-main-contant">
        <div class="mat-form-group w-100 mb-1">
          <mat-form-field class="mat-input-form" appearance="fill">
            <mat-label> Email </mat-label>
            <input type="email" class="" matInput placeholder="Email" [errorStateMatcher]="matcher"
              formControlName="email" />
            <img class="login-icon" src="../../../assets/images/auth-icon/login-icon.svg" alt="" />
          </mat-form-field>
        </div>

        <div class="mat-form-group w-100 mb-1">
          <mat-form-field class="mat-input-form" appearance="fill">
            <mat-label> Password </mat-label>
            <input type="Password" class="" matInput placeholder="Password" [errorStateMatcher]="matcher"
              formControlName="password" />
            <img class="login-icon" style="top: -3px" src="../../../assets/images/auth-icon/password-icon.svg" alt="" />
          </mat-form-field>
        </div>

        <div class="mat-form-group w-100 mb-1">
          <label class="form-control-label whitecheck float-left mb-0" for="">
            <input class="checkbox-input" id="termscheckbox" type="checkbox" value="checkvb" />
            <label for="termscheckbox" class="checkbox-label-text mb-0">
              <span class="user-terms-text">Remember me</span>
            </label>
          </label>
          <a class="forgot-text float-right" style="cursor: pointer;" (click)="forgotPassword()">Forgot password?</a>
        </div>

        <div class="mat-form-group w-100 mt-4 mb-1">
          <button class="button button-primary button-secondary w-100" style="height: 42px;">
            Sign In
          </button>
        </div>
        <div class="mat-form-group w-100 mt-2 mb-1 text-center">
          <span class="sign-up-text" (click)="userRegister()">Don’t have an account? <a> Sign up</a></span>
        </div>

      </div>
      <!-- <div class="mat-form-group w-100 mt-4 mb-0"  [routerLink]="['auth/login']">
        <a class="button button-primary button-primary-light w-100">
          Sign in Professional
        </a>
      </div> -->
    </form>
  </div>
</div>
