import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
// import { AuthApiService } from 'src/app/commonServices/auth-api.service';
// import { AuthHolderService } from 'src/app/commonServices/auth-holder.service';
import { NotificationService } from 'src/app/commonServices/notification.service';
import { UserApiService } from 'src/app/commonServices/user-api.service';
import { WebAuthInternalService } from 'src/app/commonServices/webAuthInternal.service';
import { UserLoginComponent } from '../user-login/user-login.component';
// import { UserLoginComponent } from '../user-login/user-login.component';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'NA-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss']
})


export class UserRegisterComponent implements OnInit {

  public signUpForm:FormGroup;
  public matcher = new MyErrorStateMatcher();
  public hide = true;
  public signUpUserData;

  constructor (
    // private dialog: MatDialog,
    // private dialogRef: MatDialogRef<UserRegisterComponent>,
    private formBuilder: FormBuilder,
    private userApiService: UserApiService,
    private notificationService: NotificationService,
    ) {

  }

  ngOnInit () {
    this.validateForm();
  }


  validateForm() {
      this.signUpForm = this.formBuilder.group({
        firstName: ['', [Validators.required, this.noWhitespaceValidator]],
        lastName: ['', [Validators.required, this.noWhitespaceValidator]],
        email: ['', [Validators.required, Validators.email, this.noWhitespaceValidator]],
        password: ['', [Validators.required, Validators.minLength(8),
          Validators.maxLength(25),
          Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{7,}'),
          this.noWhitespaceValidator
        ]],
        cnfPassword: ['', [Validators.required]],
        termAndCondition:[false, [Validators.required, this.matchTermAndCondition]],
      });
      this.signUpForm.get('cnfPassword').setValidators([Validators.required, this.matchPassword()])
  }


  matchPassword(): ValidatorFn{
    const self = this;
    return (ctrl): ValidationErrors | null => {
      const password = self.signUpForm.get('password');
      return ctrl.value === password.value ? null : {passwordMissmatched:true};
    }
  }

  matchTermAndCondition(ctrl): ValidationErrors | null{
      return ctrl.value ? null : {notAccepted:true};
  }



  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value && typeof control.value === "string" && control.value.trim().length === 0);
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
  get f() { return this.signUpForm.controls; };


  signUp (){
    if(this.signUpForm.invalid){
      return false;
    }
    const data = this.signUpForm.value;
    delete data.cnfPassword;
    delete data.termAndCondition;
    this.userApiService.createUser(data)
    .subscribe(res => {
      this.notificationService.get_Notification('success', 'You have been registered successfully.Please login to use additional features.')
      // this.router.navigate(['/home/dashboard']);
      // this.dialogRef.close();
    });
  }
  getUserDetail(){}





}
