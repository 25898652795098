import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UserLoginComponent } from "../website/user-auth/user-login/user-login.component";
import { UserRegisterComponent } from "../website/user-auth/user-register/user-register.component";





@Injectable ({
    providedIn: 'root'
})


export class WebAuthInternalService { 



    constructor (private dialgo: MatDialog) {

    }


    userLogin(){
        this.dialgo.open(UserLoginComponent, {
          data: {},
          width: '529px',
          
        })
    }

    userRegister(){
        this.dialgo.open(UserRegisterComponent, {
          data: {},
          width: '529px',
          // height: '100vh'
        })
    }



}