import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  message: string;
  title: string;
  yesButton?: string;
  noButton?: string;
}

@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss']
})
export class ConfirmActionComponent implements OnInit {
  message = "Would you like to delete this item?";
  title = "Delete Item"
  yesButton = "Yes";
  noButton = "No";
  constructor( public dialogRef: MatDialogRef<ConfirmActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

        this.message = data.message || "Would you like to delete this item?";
        this.title = data.title ||  'Delete Item';
        this.yesButton = data.yesButton || this.yesButton;
        this.noButton = data.noButton || this.noButton;
    }

  ngOnInit(): void {
  }

  action(type = false){
      this.dialogRef.close(type);
  }

}
