import { Injectable } from "@angular/core";
import { NotifierService } from "angular-notifier";


@Injectable({
  providedIn: 'root',
})

export class NotificationService {

  public notifier;
  userData: any


  constructor(notifierService: NotifierService) {
    this.notifier = notifierService;
  }

  get_Notification(type:string, message:string) {
    this.notifier.show({
      type: type,
      message: message
    });
  }


}
