import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './commonServices/auth-guard/auth-guard.service';

const routes: Routes = [

  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuardService],
  },

  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuardService],
  },

  {
    path: '',
    loadChildren: () => import('./website/website.module').then(m => m.WebsiteModule)
  },
  // {
  //   path: 'user-auth',
  //   loadChildren: () => import('./website/user-auth/user-auth.module').then(m => m.UserAuthModule)
  // },
  // {
  //   path: '',
  //   redirectTo: 'auth/login',
  //   pathMatch: 'full'
  // },
  // { path: '**', redirectTo: 'auth/login'}

  {
    path: 'socialMediaLogin',
    loadChildren: () => import ('./commonModule/socialMediaLogin/socialMediaLogin.module').then(m => m.SocialMediaLoginModule)
  },

];




@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
